/*
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:43:05
 * @LastEditTime : 2022-07-14 16:11:05
 * @LastEditors  : BigBigger
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginDialogIsShow: false,
    userInfo: null,
    orderInfo: null,
    payOrderId: null,
    orderDetail: null,
    payWay: null,
  },
  mutations: {
    setUserInfo (state, payload) {
      state.userInfo = payload
    },
    clearUserInfo(state, payload) {
      state.userInfo = payload
    },
    toggleDialog(state, payload) {
      state.loginDialogIsShow = payload
    },
    clearOrderInfo(state, payload) {
      state.orderInfo = payload
      state.payWay = null;
    },
    setOrderInfo(state, payload) {
      state.orderInfo = payload
    },
    setPayOrderId(state, payload) {
      state.payOrderId = payload
    },
    setOrderDetail(state, payload) {
      state.orderDetail = payload
    },
    setPayWay(state, payload) {
      state.payWay = payload
    },
    setThroughPay(state, payload) {
      state.orderInfo.throughPay = payload
    }
  },
  actions: {
    setUserInfo(context,payload) {
      context.commit('setUserInfo', payload)
    },
    clearUserInfo(context,payload) {
      context.commit('clearUserInfo', null)
    },
    toggleDialog(context,payload) {
      context.commit('toggleDialog', payload)
    },
    clearOrderInfo(context,payload) {
      context.commit('clearOrderInfo', {})
    },
    setOrderInfo(context,payload) {
      context.commit('setOrderInfo', payload)
    },
    setPayOrderId(context,payload) {
      context.commit('setPayOrderId', payload)
    },
    setOrderDetail(context,payload) {
      context.commit('setOrderDetail', payload)
    },
    setPayWay(context,payload) {
      context.commit('setPayWay', payload)
    },
    setThroughPay(context,payload) {
      context.commit('setThroughPay', payload)
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        userInfo: val.userInfo,
        orderInfo: val.orderInfo,
        orderDetail: val.orderDetail,
        payWay: val.payWay
      }
    }
  })]
})
